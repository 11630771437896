import PropTypes from 'prop-types';
import React from 'react';

import {
  SectionHowToGetFinancing,
  SectionConsultation,
  SectionLeasingAdvantages,
  SectionLeasingScheme,
} from '@product-site-frontend/shared';
import { graphql } from 'gatsby';

import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import config from '../../config/website';
import logo from '../../static/images/logo.png';
import PageBox from '../components/PageBox';
// import SectionCalculator from '../components/SectionCalculator';
import SectionAboutLeasing from '../components/SectionAboutLeasing';
import SectionBusinessSegments from '../components/SectionBusinessSegments';
import SectionMain from '../components/SectionMain';
import SectionPartners from '../components/SectionPartners';
import SEO from '../components/SEO';

IndexPage.propTypes = {
  data: PropTypes.shape({
    seo: PropTypes.object,
    aboutLeasingContent: PropTypes.object,
    leasingAdvantagesContent: PropTypes.object,
    leasingSchemeContent: PropTypes.object,
    mainSectionContent: PropTypes.object,
    tyres: PropTypes.object,
    home: PropTypes.object,
    financingContent: PropTypes.object,
  }),
};

export default function IndexPage({ data }) {
  const {
    aboutLeasingContent,
    financingContent,
    home,
    leasingAdvantagesContent,
    leasingSchemeContent,
    tyres,
  } = data;

  return (
    <PageBox>
      <SEO
        microData={{
          '@context': 'https://www.schema.org',
          '@type': 'Product',
          logo: `${config.siteUrl}${logo}`,
          name: tyres.seo.title,
          description: tyres.main.description,
          offers: {
            '@type': 'Offer',
            'priceCurrency': 'RUB',
            'price': `до ${tyres.main.dealAmount} млн.`
          }
        }}
        pageData={tyres.seo}
      />
      <Breadcrumbs
        breadcrumbs={[
          {
            href: '/',
            text: 'Домашняя страница'
          },
          {
            text: 'Лизинг шин'
          }
        ]}
        sx={{ breadcrumbsLine: { borderBottom: '1px solid #ebebeb' } }}
      />
      <SectionMain content={tyres.main} />
      <SectionAboutLeasing
        content={{ ...aboutLeasingContent, ...tyres.aboutLeasing }}
      />
      <SectionBusinessSegments content={tyres.businessSegments} />
      <SectionLeasingScheme content={leasingSchemeContent} />
      {/* <SectionCalculator /> */}
      <SectionLeasingAdvantages content={leasingAdvantagesContent} />
      <SectionHowToGetFinancing content={financingContent} />
      <SectionPartners content={home.partners} />
      <SectionConsultation />
    </PageBox>
  );
}

export const query = graphql`
  query TyresPageQuery {
    tyres: strapiTyres {
      seo {
        title
        description
        meta {
          content
          name
        }
      }
      main {
        title
        description
        term
        dealAmount
        advanceRate
        bgDesktop {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
      }
      aboutLeasing {
        title
        description
      }
      businessSegments {
        title
        cards {
          text
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
              }
            }
          }
        }
      }
    }
    home: strapiHome {
      partners {
        title
        cards {
          id
          alternativeText
          caption
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
      }
    }
    aboutLeasingContent: strapiAboutLeasing {
      title
      description
      cards {
        text
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
      }
    }
    leasingSchemeContent: strapiLeasingScheme {
      title
      caption
      steps {
        title
        description
      }
    }
    leasingAdvantagesContent: strapiLeasingAdvantages {
      cards {
        title
        description
        icon {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
      }
      title
    }
    financingContent: strapiFinancing {
      title
      steps {
        title
        description
      }
      description
      bgDesktop {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 500, placeholder: NONE)
          }
        }
      }
    }
  }
`;
